export const isContactPage = () => {
  if (typeof window !== undefined) {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    return params.get("contact") === "true";
  } else {
    return false;
  }
};

export const isErrorPage = () => {
  if (typeof window !== undefined) {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    return params.get("err") === "true";
  } else {
    return false;
  }
};

export const $ = (id) => {
  return document.getElementById(id).value;
};
