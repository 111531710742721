import * as React from "react";
import { MDBContainer } from "mdbreact";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
const isErrorPage = typeof window !== `undefined` ? require("../util") : null;

const Submitted = (props) => {
  return (
    <>
      <Header />
      <MDBContainer>
        <section className="my-5" style={{ minHeight: "60vh" }}>
          <h2 className="h1-responsive font-weight-bold text-center my-5">
            {isErrorPage && isErrorPage.isErrorPage()
              ? "Error! Please Contact For Query"
              : "Your Instance will Be Live in 10 Minutes check your Email"}
          </h2>
          <p className="grey-text text-center w-responsive mx-auto mb-5">
            {isErrorPage && isErrorPage.isErrorPage()
              ? "Please Retry After sometime"
              : "You will receive a Email With your Email, Site & Password"}
          </p>
        </section>
      </MDBContainer>
      <Footer />
    </>
  );
};

export default Submitted;
